export class Logger {

  static error(params: any): void {
    console.error(params);
  };

  static handleError({ message, error }: { message?: string; error: any }) {
    console.error({ message, error });
  }
}
