
import React, { useRef, useEffect, useState } from 'react';
import queryString from 'query-string';
import { Logger } from '../services/Logger';

const Pay = ({ location }) => {
  const [state, setState] = useState('pending');
  const formRef = useRef<HTMLFormElement>(null);
  const { payRequestId, checksum } = queryString.parse(location.search);

  useEffect(() => {
    if (!(payRequestId && checksum)) {
      const missing = [
        payRequestId ? null : 'payRequestId',
        checksum ? null : 'checksum',
      ].filter((item) => !!item);

      Logger.error({ message: 'Not all required parameters were specified', invalid: missing });
      setState('rejected');
      return;
    }

    formRef.current.submit();
  }, []);

  return (
    <form ref={formRef} action="https://secure.paygate.co.za/payweb3/process.trans" method="POST" >
      <input type="hidden" name="PAY_REQUEST_ID" value={payRequestId} />
      <input type="hidden" name="CHECKSUM" value={checksum} />
    </form>
  );
};

export default Pay;
